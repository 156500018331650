<template>
	<layout-div>
		<div class="card">
			<form>
				<div class="card-header">
					<button @click="handleSave()"
							:disabled="isSaving"
							type="button"
							class="btn btn-info mt-3 float-left">
						儲存
					</button>

					<router-link class="btn btn-default mt-3 float-right"
								to="/admin/Inverter/index">
						返回列表
					</router-link>
				</div>
				<div class="card-body">
					<div class="form-horizontal col-md-8">
						<div class="form-group row">
							<label htmlFor="id" class="col-sm-2 col-form-label">逆變器編號</label>
							<div class="col-sm-10">
								<input v-model="model.id"
									type="text"
									class="form-control"
									id="id"
									name="id"
								readonly />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="housecode" class="col-sm-2 col-form-label">案場代號</label>
							<div class="col-sm-10">
								<input v-model="model.housecode"
									type="text"
									class="form-control"
									id="housecode"
									name="housecode"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="customer" class="col-sm-2 col-form-label">客戶</label>
							<div class="col-sm-10">
								<input v-model="model.customer"
									type="text"
									class="form-control"
									id="customer"
									name="customer"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="brand" class="col-sm-2 col-form-label">逆變器廠牌</label>
							<div class="col-sm-10">
								<input v-model="model.brand"
									type="text"
									class="form-control"
									id="brand"
									name="brand"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="name" class="col-sm-2 col-form-label">逆變器型號</label>
							<div class="col-sm-10">
								<input v-model="model.name"
									type="text"
									class="form-control"
									id="name"
									name="name"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="type" class="col-sm-2 col-form-label">驅動程式代碼</label>
							<div class="col-sm-10">
								<input v-model="model.type"
									type="text"
									class="form-control"
									id="type"
									name="type"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="baudrate" class="col-sm-2 col-form-label">串埠速率</label>
							<div class="col-sm-10">
								<input v-model="model.baudrate"
									type="text"
									class="form-control"
									id="baudrate"
									name="baudrate"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="sn" class="col-sm-2 col-form-label">逆變器序號</label>
							<div class="col-sm-10">
								<input v-model="model.sn"
									type="text"
									class="form-control"
									id="sn"
									name="sn"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="inverter_id" class="col-sm-2 col-form-label">逆變器位址</label>
							<div class="col-sm-10">
								<input v-model="model.inverter_id"
									type="text"
									class="form-control"
									id="inverter_id"
									name="inverter_id"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="board_id" class="col-sm-2 col-form-label">面板組編號(PV1)</label>
							<div class="col-sm-10">
								<input v-model="model.board_id"
									type="text"
									class="form-control"
									id="board_id"
									name="board_id"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="capacity" class="col-sm-2 col-form-label">面板組KW數(PV1)</label>
							<div class="col-sm-10">
								<input v-model="model.capacity"
									type="text"
									class="form-control"
									id="capacity"
									name="capacity"
								required />
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="board_id2" class="col-sm-2 col-form-label">面板組編號(PV2)</label>
							<div class="col-sm-10">
								<input v-model="model.board_id2"
									type="text"
									class="form-control"
									id="board_id2"
									name="board_id2"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="capacity2" class="col-sm-2 col-form-label">面板組KW數(PV2)</label>
							<div class="col-sm-10">
								<input v-model="model.capacity2"
									type="text"
									class="form-control"
									id="capacity2"
									name="capacity2"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="board_id3" class="col-sm-2 col-form-label">面板組編號(PV2)</label>
							<div class="col-sm-10">
								<input v-model="model.board_id3"
									type="text"
									class="form-control"
									id="board_id3"
									name="board_id3"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="capacity3" class="col-sm-2 col-form-label">面板組KW數(PV3)</label>
							<div class="col-sm-10">
								<input v-model="model.capacity3"
									type="text"
									class="form-control"
									id="capacity3"
									name="capacity3"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="dt1" class="col-sm-2 col-form-label">啟用時間</label>
							<div class="col-sm-10">
								<input v-model="model.dt1"
									type="text"
									class="form-control"
									id="dt1"
									name="dt1"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="edit_date" class="col-sm-2 col-form-label">最新修改時間</label>
							<div class="col-sm-10">
								<input v-model="model.edit_date"
									type="text"
									class="form-control"
									id="edit_date"
									name="edit_date"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="disuse" class="col-sm-2 col-form-label">汰除</label>
							<div class="col-sm-10">
								<input v-model="model.disuse"
									type="text"
									class="form-control"
									id="disuse"
									name="disuse"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label htmlFor="note" class="col-sm-2 col-form-label">備註</label>
							<div class="col-sm-10">
								<input v-model="model.note"
									type="text"
									class="form-control"
									id="note"
									name="note"
								/>
							</div>
						</div>

					</div>
				</div>
			</form>
		</div>
	</layout-div>
</template>

<script>
	import LayoutDiv from '@/components/LayoutDiv.vue';
	import { getInverter, postInverter } from '@/api/Inverter.js'

	export default {
		name: 'InverterEdit',
		components: {
			LayoutDiv,
		},
		data() {
			return {
				model: {
					id: '',
					housecode: '',
					customer: '',
					brand: '',
					name: '',
					type: '',
					baudrate: '',
					sn: '',
					inverter_id: '',
					board_id: '',
					capacity: '',
					board_id2: '',
					capacity2: '',
					board_id3: '',
					capacity3: '',
					dt1: '',
					edit_date: '',
					disuse: '',
					note: '',

				},
				isSaving: false,
			};
		},
		created() {
			const id = this.$route.params.id;

			getInverter(id).then(rep => {
				this.model = rep.data;
			});

		},
		methods: {
			handleSave() {
				if (this.isSaving == true) return;

				this.isSaving = true;
				postInverter(this.model).then(rep => {

					this.isSaving = false;
					return rep;
				})
					.catch(error => {
						this.isSaving = false;
						return error
					});
			},
		},
	};
</script>